import queryString from 'query-string';

// this const is just here to prevent prettier to do ugly stuff...
const separator = '?';

/**
 * Safely append (additional) parameters to url. Existing parameters with the
 * same name get replaced.
 */
export const appendParameters = (url: string, parameters: { [id: string]: string }) => {
  // To keep this method generic, we can't use the ES6 URL, which only works
  // with absolute urls. So off we go, having fun splitting the url by hand.
  // This will explode once we do fun stuff like absolute urls with a
  // questionmark in the password.
  const [path, search] = url.split(separator, 2);
  const parsedParams = queryString.parse(search);

  const result = { ...parsedParams, ...parameters };
  return path + separator + queryString.stringify(result);
};

const leadingSlashesRegex = /^\/+/;
const trailingSlashesRegex = /\/+$/;

/**
 * Appends the given path components to the URL.
 * It will ensure that only one slash is between individual components
 * and that there is a trailing slash at the end.
 */
export function appendPathComponents(base: string, ...pathComponents: string[]): string {
  const components = [
    // Trim
    base.replace(trailingSlashesRegex, ''),
    ...pathComponents.map((component) =>
      component.replace(leadingSlashesRegex, '').replace(trailingSlashesRegex, '')
    ),
  ];

  return `${components.join('/')}/`;
}

export function containsLink(text: string): boolean {
  const urlPattern = /https?:\/\/[^\s]+|www\.[^\s]+/gi;
  return urlPattern.test(text);
}

export function isTrustedLink(url: string, trustedSites: string[]): boolean {
  try {
    const { hostname } = new URL(url);
    return trustedSites.some((site: string) => hostname.includes(site));
  } catch (error) {
    return true;
  }
}
