/**
 * Tries to parse the given text as JSON, returning `null` if the input is missing or not valid JSON.
 */
export function tryJsonParse<T = unknown>(text: string | null | undefined): T | null {
  if (!text) {
    return null;
  }

  try {
    return JSON.parse(text);
  } catch (e) {
    return null;
  }
}
