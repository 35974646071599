import React from 'react';

import { navigateTo } from 'flatfox_common/ui/utils';
import { tryJsonParse } from 'flatfox_common/utils/json';
import { createLazyComponent } from 'flatfox_common/utils/lazyComponent';

import { attachRoot } from 'flatfox_website/scripts/reactDOM';

import renderApply from 'flatfox/views/direct_submit';
import renderProjectTable from 'flatfox/views/project_table';
import { render as renderSearch } from 'flatfox/views/search';

import renderTicket from 'flatfox_tickets/client';

import {
  BannerAdWidgetKeywordData,
  IdVariantType,
} from 'flatfox_ads/client/banner_ads/types';

function setup() {
  setupSimilarListings();
  setupEmbeddedSearch();
  setupEmbeddedProjectTable();
  setupEmbeddedApply();
  setupEmbeddedTicket();
  setupFileUploads();
  setupSelectionWidget();
  setupAdSlot();
}

function setupSimilarListings() {
  augment('data-similar-listings', (node) => {
    const SimilarListings = createLazyComponent(
      () => import('flatfox_common/ui/listing/SimilarListings')
    );

    attachRoot(
      <SimilarListings
        listingPk={getIntAttr(node, 'data-listing-pk')}
        organizationPk={getIntAttr(node, 'data-organization-pk')}
        selectionPk={getIntAttr(node, 'data-selection-pk')}
        count={getIntAttr(node, 'data-count')}
        urlTemplateListingDetail={getStrAttr(node, 'data-url-template-listing-detail')}
      />,
      node
    );
  });
}

function setupEmbeddedSearch() {
  augment('data-embedded-search', (node) => {
    renderSearch(node, {
      selectionPk: getIntAttr(node, 'data-selection-pk'),
      organizationPk: getIntAttr(node, 'data-organization-pk'),
      embedded: true,
      urlTemplateListingDetail: getStrAttr(node, 'data-url-template-listing-detail'),
    });
  });
}

function setupEmbeddedProjectTable() {
  augment('data-embedded-project-table', (node) => {
    // hack such that the table is at least horizontally scrollable on mobile.
    // i tried to set the 'overflox-x' in embed.js/createIFrame, but it seems
    // iframe-resizer reverts that.
    // TODO flatfox-ag/product#203: remove this hack and make table responsive
    node.style['overflow-x'] = 'auto';
    renderProjectTable(node, {
      selectionPk: getIntAttr(node, 'data-selection-pk'),
      urlTemplateListingDetail: getStrAttr(node, 'data-url-template-listing-detail'),
      urlTemplateDossierSubmit: getStrAttr(node, 'data-url-template-dossier-submit'),
    });
  });
}

function setupEmbeddedApply() {
  augment('data-embedded-apply', (node) => {
    renderApply(node, {
      selectionPk: getIntAttr(node, 'data-selection-pk'),
      organizationPk: getIntAttr(node, 'data-organization-pk'),
      allow_search: getBoolAttr(node, 'data-allow-search'),
    });
  });
}

function setupEmbeddedTicket() {
  augment('data-embedded-ticket', (node) => {
    renderTicket(node, {
      ownerPk: getIntAttr(node, 'data-owner-pk'),
      hasInventory: getBoolAttr(node, 'data-has-inventory'),
      agencyName: node.getAttribute('data-agency-name'),
      agencyLogoUrl: node.getAttribute('data-agency-logo-url'),
    });
  });
}

function setupFileUploads() {
  augment('data-clearable-fileinput', (node) => {
    const value = node.querySelector('[data-file-value]');
    const reset = node.querySelector('[data-file-reset]');
    if (!value) {
      return;
    }
    node.addEventListener('change', (event) => {
      const fileList = event.target.files;
      if (!fileList || fileList.length === 0) {
        return;
      }
      value.innerHTML = fileList[0].name;
    });

    if (reset) {
      reset.addEventListener('change', (event) => {
        if (event.target.checked) {
          value.style = 'text-decoration: line-through;';
        } else {
          value.style = '';
        }
      });
    }
  });
}

function setupSelectionWidget() {
  augment('data-selection', (node) => {
    const SelectionWidget = createLazyComponent(
      () => import('flatfox_common/ui/selection/SelectionWidget')
    );
    const backUrl = node.getAttribute('data-back-url');
    const navigateBack = backUrl ? () => navigateTo(backUrl) : undefined;
    attachRoot(
      <SelectionWidget
        selectionPk={getIntAttr(node, 'data-selection-pk')}
        onCancel={navigateBack}
        onSuccess={navigateBack}
      />,
      node
    );
  });
}

function setupAdSlot() {
  augment('data-banner-ad-slot', (node) => {
    const BannerAdWidget = createLazyComponent(
      () => import('flatfox_ads/client/banner_ads/BannerAdWidget')
    );

    const adSlotName = node.getAttribute('data-ad-slot-name') as IdVariantType;
    const adKeywords = tryJsonParse<BannerAdWidgetKeywordData>(
      node.getAttribute('data-ad-slot-keywords')
    );

    if (adSlotName) {
      attachRoot(
        <BannerAdWidget adSlotName={adSlotName} keywordData={adKeywords} />,
        node
      );
    }
  });
}

function augment(selector: string, func: { (node: Element): void }) {
  const els = document.querySelectorAll(`[${selector}]`);
  for (let i = 0; i < els.length; i += 1) {
    const node = els[i];
    func(node);
  }
}

function getIntAttr(node: Element, name: string) {
  const attr = node.getAttribute(name);
  if (attr) {
    return parseInt(attr, 10);
  }
  return undefined;
}

function getStrAttr(node: Element, name: string) {
  const attr = node.getAttribute(name);
  if (attr !== null) {
    return attr;
  }
  return undefined;
}

function getBoolAttr(node: Element, name: string) {
  const attr = node.getAttribute(name);
  if (attr === 'true') {
    return true;
  }
  return false;
}

window.flatfox.augments = { setup };
